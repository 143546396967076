import React from "react";
import { Container } from "@/components/landing/Container";

interface SectionTitleProps {
  preTitle?: string;
  title?: string;
  align?: "left" | "center";
  children?: React.ReactNode;
}

export const SectionTitle = (props: Readonly<SectionTitleProps>) => {
  return (
    <Container
      className={`flex w-full flex-col m-0 p-0 gap-6 ${
        props.align === "left" ? "" : "items-center justify-center text-center"
      }`}
    >
      {props.preTitle && (
        <div className="text-sm font-bold tracking-wider text-indigo-600 uppercase">
          {props.preTitle}
        </div>
      )}

      {props.title && (
        <h2 className="text-center text-slate-800 text-2xl leading-loose sm:text-3xl sm:leading-9 lg:text-4xl lg:leading-10 font-bold m-0 p-0">
          {props.title}
        </h2>
      )}

      {props.children && (
        <p className="max-w-[708px] text-center text-slate-600 text-sm leading-tight sm:text-base sm:leading-normal font-normal">
          {props.children}
        </p>
      )}
    </Container>
  );
};
