"use client";

import { ClientUser } from "@/types/ClientUser";
import { usePathname } from "next/navigation";
import React, { useCallback, useState } from "react";
import { isAdminUser } from "@/lib/utils";
import Menu from "./Menu";
import NavLink from "./NavLink";
import {
  HomeIcon,
  PlusCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import {
  HomeIcon as HomeIconOutline,
  PlusCircleIcon as PlusCircleIconOutline,
  UserCircleIcon as UserCircleIconOutline,
} from "@heroicons/react/24/outline";
import Image from "next/image";
import { getPublicAssetPath } from "@/lib/asssetUtils";
import { useUserContext } from "../providers/user-provider";

const MobileNavbar: React.FC<{ user: ClientUser | null }> = React.memo(
  ({ user }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const pathname = usePathname();
    const isAdmin = isAdminUser(user);
    const userContext = useUserContext();

    const toggleMenu = useCallback(() => {
      setIsMenuOpen((prev) => !prev);
    }, []);

    const pawnOutlineSrc = getPublicAssetPath("/assets/PawnOutline.svg");
    const pawnSolidSrc = getPublicAssetPath("/assets/PawnSolid.svg");

    const PawnOutlineIcon = React.memo(() => (
      <Image src={pawnOutlineSrc} alt="pawn" width={24} height={24} />
    ));

    const PawnSolidIcon = React.memo(() => (
      <Image src={pawnSolidSrc} alt="pawn" width={24} height={24} />
    ));

    if (userContext.sideNavOpen) {
      return null;
    }

    return (
      <nav
        className={`sm:hidden h-mobile-navbar fixed bottom-0 left-0 right-0 z-50 px-4 py-2 bg-white ${!isMenuOpen ? "rounded-tl-2xl rounded-tr-2xl" : ""} shadow justify-between items-center flex`}
      >
        <Menu
          user={user}
          isAdmin={isAdmin}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
        />

        <NavLink
          href={user ? "/home" : "/"}
          icon={HomeIconOutline}
          activeIcon={HomeIcon}
          isActive={user ? pathname === "/home" : pathname === "/"}
          title="Home"
        />
        <NavLink
          href="/upload"
          icon={PlusCircleIconOutline}
          activeIcon={PlusCircleIcon}
          isActive={pathname === "/upload"}
          title="Upload"
        />

        <NavLink
          href="/games"
          icon={PawnOutlineIcon}
          activeIcon={PawnSolidIcon}
          isActive={pathname.startsWith("/game")}
          title="Games"
        />

        <button
          onClick={toggleMenu}
          className="h-11 flex flex-col items-center justify-center gap-1"
        >
          {pathname.startsWith("/account") ? (
            <UserCircleIcon className="text-brand-secondary" />
          ) : (
            <UserCircleIconOutline />
          )}
          <div
            className={`self-stretch text-center text-xs leading-none ${
              pathname.startsWith("/account")
                ? "text-brand-secondary font-bold"
                : "text-slate-800 font-normal"
            }`}
          >
            Profile
          </div>
        </button>
      </nav>
    );
  },
);

export default MobileNavbar;
