import Link from "next/link";
import React from "react";

interface NavLinkProps {
  href: string;
  icon: React.ComponentType<{ className?: string }> | JSX.Element;
  activeIcon: React.ComponentType<{ className?: string }> | JSX.Element;
  isActive: boolean;
  title: string;
}

const NavLink: React.FC<NavLinkProps> = ({
  href,
  icon: Icon,
  activeIcon: ActiveIcon,
  isActive,
  title,
}) => {
  const renderIcon = () => {
    if (React.isValidElement(Icon)) {
      return Icon;
    }
    // If Icon is a component type
    const IconComponent = Icon as React.ComponentType<{ className?: string }>;
    return (
      <IconComponent
        className={`w-6 h-6 justify-center items-center inline-flex ${
          isActive ? "text-brand-secondary" : "text-slate-800"
        }`}
      />
    );
  };

  const renderActiveIcon = () => {
    if (React.isValidElement(ActiveIcon)) {
      return ActiveIcon;
    }
    // If ActiveIcon is a component type
    const ActiveIconComponent = ActiveIcon as React.ComponentType<{
      className?: string;
    }>;
    return (
      <ActiveIconComponent
        className={`w-6 h-6 justify-center items-center inline-flex ${
          isActive ? "text-brand-secondary" : "text-slate-800"
        }`}
      />
    );
  };

  return (
    <Link
      href={href}
      className="h-11 flex flex-col items-center justify-center gap-1"
    >
      {isActive ? renderActiveIcon() : renderIcon()}
      <div
        className={`self-stretch text-center text-xs leading-none ${
          isActive
            ? "text-brand-secondary font-bold"
            : "text-slate-800 font-normal"
        }`}
      >
        {title}
      </div>
    </Link>
  );
};

export default NavLink;
