"use client";
import { useCallback, useEffect, useState } from "react";
import {
  fetchPricingItems,
  usePricingItemsStore,
} from "@/app/(dashboard)/stores/pricingItemsStore";
import { usePricingStore } from "@/app/(dashboard)/stores/pricingStore";

export const usePricingItemsFetch = () => {
  //responsible for data about prices
  const pricingStore = usePricingStore();

  //responsible for data about frontend plan descriptions, stored in db
  const pricingItemsStore = usePricingItemsStore();
  const { pricingItems } = pricingItemsStore;

  const [isHydrated, setIsHydrated] = useState(false);
  useEffect(() => {
    if (!isHydrated) {
      setIsHydrated(true);
      return;
    }
    if (!pricingStore.plans) return;
    const fetchPricingItemsIntoStoreState = async () => {
      if (!pricingItems) {
        console.log("fetching pricing items");
        const { pricingItems } = await fetchPricingItems(pricingStore);
        pricingItemsStore.setPricingItems(pricingItems);
      }
    };
    fetchPricingItemsIntoStoreState();
  }, [pricingStore.plans, pricingItems, isHydrated]);

  return {
    pricingItems,
  };
};
