import(/* webpackMode: "eager" */ "/web/next13-chess/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/next13-chess/components/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/next13-chess/components/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/next13-chess/components/mobile-navbar/MobileNavbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/next13-chess/components/providers/AuthContext.tsx");
;
import(/* webpackMode: "eager" */ "/web/next13-chess/components/providers/config-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/web/next13-chess/components/providers/modal-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProvidesTheQueryClient"] */ "/web/next13-chess/components/providers/query-client-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserContextProvider"] */ "/web/next13-chess/components/providers/user-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/web/next13-chess/components/Stores/PricingFetcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/web/next13-chess/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/web/next13-chess/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/web/next13-chess/node_modules/simplebar-react/dist/simplebar.min.css");
