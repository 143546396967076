"use client";

import React, { useEffect } from "react";
import { X } from "lucide-react";
import { ClientUser } from "@/types";
import { signOut } from "next-auth/react";
import MenuLink from "./MenuLink";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface MenuProps {
  user: ClientUser | null;
  isAdmin: boolean;
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

const Menu: React.FC<MenuProps> = ({
  user,
  isAdmin,
  isMenuOpen,
  toggleMenu,
}) => {
  const pathname = usePathname();

  const menuVisibility = !isMenuOpen ? "top-full" : "bottom-[calc(100%-4px)]";

  useEffect(() => {
    console.log({ isMenuOpen });
  }, [isMenuOpen]);

  return (
    <div
      className={`flex flex-col items-stretch gap-2 px-4 pt-2 pb-4 sm:hidden absolute left-0 right-0 bg-white rounded-tl-2xl rounded-tr-2xl overflow-hidden origin-top duration-300 ${menuVisibility}`}
    >
      <XMarkIcon
        className="cursor-pointer h-6 w-6 self-end"
        onClick={toggleMenu}
      />
      <div className="flex flex-col items-stretch gap-8 ">
        {user && (
          <MenuLink
            href="/account/profile"
            isActive={pathname.includes("account")}
            onClick={toggleMenu}
          >
            Profile
          </MenuLink>
        )}
        {user && (
          <MenuLink
            href="/add_pgn"
            isActive={pathname === "/add_pgn"}
            onClick={toggleMenu}
          >
            Add PGN
          </MenuLink>
        )}
        {!user && (
          <Link
            href="/login"
            onClick={toggleMenu}
            className="h-[42px] px-4 py-3 rounded-lg border border-brand-secondary text-center text-brand-secondary text-sm font-semibold leading-tight"
          >
            Sign In
          </Link>
        )}
        {user && (
          <Link
            href="/"
            className="h-[42px] px-4 py-3 rounded-lg border border-brand-secondary text-center text-brand-secondary text-sm font-semibold leading-tight"
            onClick={(event) => {
              event.preventDefault();
              signOut({ callbackUrl: `${window.location.origin}/login` });
            }}
          >
            Sign Out
          </Link>
        )}
      </div>
    </div>
  );
};

export default Menu;
