"use client";

import Script from "next/script";

// load google tag manager scripts according to user consent

interface GoogleTagManagerProps {
  googleTagId: string;
}

const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({ googleTagId }) => {
  return (
    <>
      <Script
        id="google-tag-manager-consent"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
         window.dataLayer = window.dataLayer || [];

        window.gtag = function () {
            dataLayer.push(arguments);
        }

        if (localStorage.getItem('cookie-consent') === null) {
            gtag('consent', 'default', {
                'analytics_storage': 'denied',
                'ad_storage': 'denied',
                'ad_personalization': 'denied',
                'ad_user_data': 'denied',
            });
        } else {
            gtag('consent', 'default', JSON.parse(localStorage.getItem('cookie-consent')));
        }
        `,
        }}
      />
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${googleTagId}');`,
        }}
      />
    </>
  );
};

export const gtag = (...args: any[]) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag(...args);
  }
};

export default GoogleTagManager;
