"use client";

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Check, Loader2, Zap } from "lucide-react";
// import { toast } from "react-hot-toast";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { useLoadingModal } from "@/hooks/useLoadingModal";

interface LoadingModalProps {}

export const LoadingModal = ({}: LoadingModalProps) => {
  const loadingModal = useLoadingModal();
  //   const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return false;
  }

  return (
    <Dialog open={loadingModal.isOpen} onOpenChange={loadingModal.onClose}>
      {/* <Dialog open={true} onOpenChange={loadingModal.onClose}> */}
      <DialogContent className="max-w-[68rem]">
        <DialogHeader>
          <DialogTitle className="flex justify-center items-center flex-col gap-y-4 pb-2">
            <div className="flex items-center gap-x-2 font-bold text-xl">
              Loading
            </div>
          </DialogTitle>
          <DialogDescription className="flex  flex-col text-center pt-2 space-y-2 text-zinc-900 font-medium">
            <div className="w-full items-center justify-center">
              <Loader2 className="mx-auto items-center w-16 h-16 animate-spin" />
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogContent></DialogContent>
        <DialogFooter>
          <div className="mx-auto items-center text-muted-foreground">
            XChess AI is analyzing ...
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
